export interface Color {
    type: ColorOptions;
    rotation: number;
    points: { location: number; color: { hex: Hex; rgb: Rgba } }[];
}

export enum ColorOptions {
    Transparent = 'transparent',
    Solid = 'solid',
    Linear = 'linear',
    Radial = 'radial'
}

export interface SimpleColor {
    hex: Hex;
    rgb: Rgba;
}

export interface BrandGuideColor {
    brand: string;
    brandLabel?: string;
    title?: string;
    color: Color;
}

export type Hex = string;

export interface Rgba {
    r: number;
    g: number;
    b: number;
    a?: number;
}

export interface Cmyk {
    c: number;
    m: number;
    y: number;
    k: number;
}
